import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loading$: Observable<boolean>;

  constructor() {
    this.loading$ = this.loadingSubject as Observable<boolean>;
  }

  setLoadingOn(): void {
    this.loadingSubject.next(true);
  }
  setLoadingOff(): void {
    this.loadingSubject.next(false);
  }
}
