import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {LoadingService} from './services/loading.service';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title,
              private loadingService: LoadingService,
              private breadcrumbService: BreadcrumbService,
              private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.setLoadingOn();
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loadingService.setLoadingOff();
      }
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      // console.log(crumbs);
      this.titleService.setTitle(this.createTitle(crumbs));
    });
  }
  onActivate(event: any): void{
    window.scroll(0, 0);
  }
  // tslint:disable-next-line:typedef
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'KCD Trust';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${title}${routeTitle}`;
  }

  private titlesToString(titles: any[]): string {
    return titles.reduce((prev, curr) => {
      return `${prev} | ${curr.displayName}`;
    }, '');
  }
}
